/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Layout from "~/components/Layout";
import { hideEmailMask } from "~/utils/mask";
import ModalOneButton from "~/components/Modals/OneButtonPopup";

import themes from "~/themes";
import * as S from "../styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const LoadingCPF = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const { state } = useLocation();
  const history = useHistory();

  const GETClientByCPFNoAuth = useApi(
    Endpoints.cliente.getClientByCPFNoAuth.replace("PARAM_CPF", state?.cpf),
    ApiMethod.GET
  );

  useEffect(() => {
    getClientByCPFNoAuth();
  }, []);

  async function getClientByCPFNoAuth() {
    const response = await GETClientByCPFNoAuth.callApi();
    const data = response.data;

    if (response.status >= 200 && response.status < 300) {
      if (data.celular) {
        setTimeout(() => {
          history.push("/Fatura-Validate", {
            phone: data.celular,
            cpf: state?.cpf,
            email: hideEmailMask(data.email),
          });
        }, 4000);
      }
    } else {
      setModalText(data.mensagem);
      setModalIsOpen(true);
    }
  }

  return (
    <Layout auth={false}>
      <ModalOneButton
        ishtml
        text={modalText}
        buttonText="Ok"
        icon={themes.components.icons.tick}
        visibility={modalIsOpen}
        setVisibility={(bool) => setModalIsOpen(bool)}
        onClose={() => {
          history.push("/fatura-cpf");
        }}
        onClick={() => {
          history.push("/fatura-cpf");
        }}
      />
      <S.Content>
        <S.Main>
          <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
          <p>
            <strong>Preparando teus dados</strong>...
          </p>
          <span>Aguarde! Estamos preparando tuas informações!</span>
        </S.Main>
      </S.Content>
    </Layout>
  );
};

export default LoadingCPF;
