import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Icons from "react-icons/md";
import Button from "../../Button";
import CardView from "../../CardView";
import ContainerFlex from "../../ContainerFlex";

import * as St from "./styles";

const Dialog = ({
  children,
  icon,
  open,
  message,
  positiveLabel,
  positiveAction,
  negativeLabel,
  negativeAction,
  widthInPercent,
  widthInPixel,
  Loading,
  title,
  closeButton = false,
  buttonDisabledPositive = false,
  buttonsLeft,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) setIsOpened(true);
    else setIsOpened((prev) => (prev === true ? false : null));
  }, [open]);

  const close = () => {
    if (negativeAction) negativeAction();
    setIsOpened(false);
  };

  const confirm = () => {
    if (positiveAction) {
      setIsLoading(true);
      positiveAction();
      setIsLoading(false);
    }
  };

  return (
    <St.Container show={isOpened}>
      <CardView space widthPixel={widthInPixel} width={widthInPercent}>
        <ContainerFlex center>
          {/* Header */}
          {icon && (
            <ContainerFlex>
              <ContainerFlex row align="center">
                <St.Icon src={icon} />
                <St.TitleHeader>{title}</St.TitleHeader>
              </ContainerFlex>
            </ContainerFlex>
          )}

          {/* Content */}
          <ContainerFlex padding={16}>
            {closeButton ? (
              <St.CloseIcon onClick={close}>
                <MdClose />
              </St.CloseIcon>
            ) : (
              ""
            )}
            {message && (
              <St.Message alignCenter={closeButton}>{message}</St.Message>
            )}
            {children}
          </ContainerFlex>

          <>
            <St.DividerLine />

            {/* Buttons */}
            {buttonsLeft ? (
              <ContainerFlex row padding={16} end gap="20">
                {negativeLabel && (
                  <Button fitContent onClick={close} outlined>
                    <span>{negativeLabel || "Fechar"}</span>
                  </Button>
                )}
                {positiveAction && (
                  <Button
                    fitContent={!!negativeLabel || widthInPercent != null}
                    fullWidth={!negativeLabel}
                    onClick={confirm}
                    disabled={buttonDisabledPositive}
                    loading={Loading}
                  >
                    <span>{positiveLabel || "Confirmar"}</span>
                  </Button>
                )}
              </ContainerFlex>
            ) : (
              <>
                <ContainerFlex row spaceAround wrap padding={16}>
                  {negativeLabel && (
                    <Button
                      fitContent
                      onClick={close}
                      outlined
                      marginBottom={8}
                    >
                      <span>{negativeLabel || "Fechar"}</span>
                    </Button>
                  )}
                  {positiveAction && (
                    <Button
                      fitContent={!!negativeLabel || widthInPercent != null}
                      fullWidth={!negativeLabel}
                      onClick={confirm}
                      disabled={buttonDisabledPositive}
                      loading={Loading}
                    >
                      <span>{positiveLabel || "Confirmar"}</span>
                    </Button>
                  )}
                </ContainerFlex>
              </>
            )}
          </>
        </ContainerFlex>
      </CardView>
    </St.Container>
  );
};

export default Dialog;
