import React, { useState } from "react";
import { Label, RadioGroupContainer, RadioInput, RadioTitle } from "./styles";

const RadioButtonGroup = ({ options, name, onChange, defaultValue }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || null);

  const handleChange = (index) => {
    setSelectedValue(index);
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <RadioGroupContainer>
      {options.map((option) => (
        <Label key={option.index}>
          <RadioInput
            type="radio"
            name={name}
            checked={selectedValue === option.index}
            onChange={() => handleChange(option.index)}
          />
          <RadioTitle>{option.title}</RadioTitle>
        </Label>
      ))}
    </RadioGroupContainer>
  );
};

export default RadioButtonGroup;
