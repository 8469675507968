import styled from "styled-components";
import themes from "~/themes";

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const RadioInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;

  &:checked {
    border-color: ${themes.colors.orange.n2};
    border-width: 6px;
    padding: 0.5%;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(231,16,55,0.5);
  }
`;

export const RadioTitle = styled.span`
  font-size: 14px;
  color: #4b5563;
  transition: color 0.2s ease;

  ${Label}:hover & {
    color: #1f2937;
  }
`;
