const Autorizador = {
  validateCard: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/noauth/token-cartao`,
  putChangePassword: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/alterar-senha`,
  putActiveCard: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/ativar-cartao`,
  postCartao: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/validar-cartao`,
  getBlockReason: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/motivos-bloqueio/PARAM_ORIGIN`,
  putBlockCard: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/bloquear-cartao`,
  putUnblockCard: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/ativar-desbloquear`,
  postExtrato: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/extrato/PARAM_LIMITE/PARAM_DATA`,
  postSaldo: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/extrato/saldo`,
  getProfile: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/perfil-cliente/PARAM_USER`,
  postValidatePassword: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/noauth/validar-senha`,
  postCcValidate: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/validar-cartao`,
  dataUserPUT: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/perfil-cliente/PARAM_DATA`,
  getClientCurrentCategory: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/listar-categorias-cartao-atual/PARAM_CPF`,
};

const Cliente = {
  getClientByCPF: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-cpf/PARAM_CPF`,
  getSearchClientWithdrawPersonalData: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-cliente-emprestimo-saque-avista-dados-pessoais/PARAM_CPF`,
  getClientByCPFNoAuth: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-cliente-cpf/PARAM_CPF`,
  getMotivoBloqueio: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/retornar-motivoBloqueio-numeroCartao?numeroCartao=PARAM_NUMERO`,
  getNoAuthInvoice: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-cliente-ultima-fatura/PARAM_CPF?tipo_envio=PARAM_TIPO_ENVIO`,
  getUserIdentification: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-dados-identificacao-carteira/PARAM_CPF?tipoProduto=PARAM_TIPO`,
  postUserValidateEmail: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/validar-email-disponivel-cliente/PARAM_EMAIL/PARAM_CPF`,
  putChangedAddress: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/atualizar-cliente/emprestimo/endereco`,
  updateClientByLending: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/atualizar-cliente/emprestimo`,
  getClientCPFByLending: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-cliente-cpf-emprestimo/PARAM_CPF`,
  getPlatinumTerms: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/contrato-platinum-assinado/ID_PROPOSAL`,
  getConsultZeussBalance: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/consultar-saldo-zeuss/PARAM_CPF`,
  getConsultZeussPhone: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/obter-celular-zeuss/PARAM_CPF`,
};

const Email = {
  postSendInvoiceEmail: `${process.env.REACT_APP_MS_EMAIL}/v1/email/email-fatura/PARAM_EMAIL`,
  forgotPassword: `${process.env.REACT_APP_MS_EMAIL}/v1/email/noauth/link-senha-email-cartao/PARAM_EMAIL`,
};

const Notificacao = {
  putCodeValidate: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/push/validar`,
  postSendToken: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/push`,
  postSendTokenSMSOne: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/whatsapp/enviar-whatsapp-codigo-desbloqueio`,
  postSendTokenSMS: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/sms/enviar-sms-notificacao`,
  postSendVerificationCodeToEmail: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/email/enviar-email-notificacao`,
  putCodeValidatePhone: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/push/validar-celular`,
  postSMSWithCPF: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/sms/enviar-sms-notificacao-cpf/PARAM_CPF`,
  putCodeValidateCPF: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/push/validar-cpf`,
};

const Proposta = {
  getProposta: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=PARAM_PROPOSALID`,
  getContractedBenefits: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/buscar-beneficio-proposta/PARAM_ID_PROPOSTA`,
  getTotalCredit: `${process.env.REACT_APP_MS_FECHAMENTO}/v1/fechamentos/obter-quantidade-fechamento-faturas?dataFechamento=PARAM_DATA_FECHAMENTO`,
  postAddWalletProposal: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/noauth/criar-proposta-carteira`,
  saveClientIdentification: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/salvar-identificacao-cliente`,
};

const Relatorio = {
  getInvoiceList: `${process.env.REACT_APP_MS_RELATORIOS}/v1/relatorios/obter-lista-faturas?cpf=PARAM_CPF`,
  getInvoicePDF: `${process.env.REACT_APP_MS_RELATORIOS}/v1/relatorios/gerar-fatura/PARAM`,
  postFaturaGeradaNoAuth: `${process.env.REACT_APP_MS_RELATORIOS}/v1/relatorios/noauth/ultima-fatura-gerada`,
};

const Usuario = {
  getUserByCPF: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/usuario-porCpf/PARAM_CPF`,
  getUserPicture: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/usuario-perfil?id=PARAM_PICTURE`,
  postLogin: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/auth/login-cliente`,
  postEditUserPicture: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/editar-foto`,
  changePasswordNew: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/confirmar-alterar-senha-cartao/PARAM_ID`,
  postDefineNewPassword: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/alterar-senha-usuario`,
  postAlterarSenha: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/alterar-senha-usuario`,
  postValidarSenhaUsu: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/validar-senha-usuario`,
};

const Walltet = {
  getCreditCards: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/listar-cartoes/PARAM_CPF`,
  getPrincipalCard: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/buscar-cartao-principal/PARAM_CPF`,
  addCard: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/cadastrar-cartao`,
  deleteCard: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/deletar-cartao/PARAM_ID`,
  getExtract: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/extrato/?cpf=PARAM_CPF&dataFinal=PARAM_DAF&dataInicial=PARAM_DAI&idCartao=PARAM_ID_CARD&pagina=PARAM_PAG&totalItens=PARAM_ITENS`,
  putPrincipalCard: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/definir-cartao-principal/PARAM_ID_CARTAO?principal=PARAM_BOOLEAN`,
  postSendWhatsappCode: `${process.env.REACT_APP_MS_CARTEIRA}/v1/notificacoes/whatsapp/enviar-whatsapp-codigo-carteira-digital`,
};

const Product = {
  addClientProduct: `${process.env.REACT_APP_MS_PRODUTO}/v1/produtos/adicionar-produto-cliente/`,
  addClientProductNoAuth: `${process.env.REACT_APP_MS_PRODUTO}/v1/produtos/noauth/adicionar-produto-cliente/`,
};

const Charge = {
  getInvoiceQrCode: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/pix-gerar-qrcode-fatura?idFatura=PARAM_ID`,
};

const Eventos = {
  putReadLead: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/atualizar-leitura-evento/PARAM_ID`,
  postIncludeEvent: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/incluir-evento`,
};

const Payments = {
  postSendTransactionValidationCard: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/pagamentos/cartao-credito/envia-transacao-validacao-cartao`,
  postValidateTransaction: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/pagamentos/cartao-credito/validar-transacao-cartao`,
  postReverseValue: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/pagamentos/cartao-credito/desfazimento-transacao-validacao-cartao/PARAM_ID`,
};

const Lendings = {
  getLimitAvailable: `${process.env.REACT_APP_MS_BOX_CLIENTES}/v1/box-base-clientes/limite-disponivel-saque?cpf=PARAM_CPF`,
  postSimulationWithdrawal: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/simular`,
  getContractLending: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/contrato-emprestimo-pdf-online/PARAM_ID`,
  getBanks: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/buscar-bancos`,
  postAmountRelease: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/simular/valor-liberado`,
  postCreditLimit: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/simular/credito-limite`,
  postInstallmentValue: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/simular/valor-parcela`,
  postClientRegister: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/cliente`,
  postContract: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/contrato`,
  postContractedLending: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/contratar`,
  getValue: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/configuracao`,
  getLending: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/PARAM_CPF?tipoFornecedor=PARAM_TIPO`,
  postContractWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/contratar/PARAM_ID`,
  postDigitalSignature: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/assinar-digitalmente-contrato`,
  putUpdateAdditionalDataWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/atualiza-dados-adicionais-cliente/PARAM_ID`,
  putUpdateBankingDataWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/atualiza-dados-bancario-cliente/PARAM_ID`,
  getContractWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/contrato-por-cpf?cpf=PARAM_CPF`,
  getPDFContractWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/contrato-emprestimo-pdf/PARAM_ID`,
  getPDFVoucherWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/comprovante-emprestimo-pdf/PARAM_ID`,
  getPendingLending: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/emprestimo-pendente-celcoin/PARAM_CPF`,
  putCancelWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/saque/cancelar/PARAM_ID`,
  getBanksByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/obter-bancos-easy-credito`,
  getProfissionsByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/obter-profissoes-easy-credito`,
  getIssuesByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/obter-emissores-easy-credito`,
  postIncludeClientByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/incluir-cliente-easy-credito`,
  getLastProposalsByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/obter-ultima-proposta/PARAM_CPF`,
  postSearchProposalsEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/buscador-easy-credito`,
  postSendProposalEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/enviar-documento-easy-credito/ID_LOAN`,
  getStatusClientKYC: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/obter-situacao-kyc/PARAM_CPF`,
  postCreateSolicitationKYC: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/criar-solicitacao-kyc`,
  postValidateKycFreezeList: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/valida-situacao-kyc/PARAM_CPF`,
  postCreateTimeout: `${process.env.REACT_APP_MS_EMPRESTIMOS}/v1/emprestimos/criar-log-tempo-expirado-kyc`,
};

const Endpoints = {
  autorizador: Autorizador,
  cliente: Cliente,
  email: Email,
  notificacao: Notificacao,
  proposta: Proposta,
  relatorio: Relatorio,
  usuario: Usuario,
  wallet: Walltet,
  product: Product,
  charge: Charge,
  eventos: Eventos,
  lendings: Lendings,
  payments: Payments,
};

export default Endpoints;
