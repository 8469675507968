/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "~/components/Layout";
import Button from "~/components/Button";
import Input from "~/components/Input";
import { cpfMask } from "~/utils/mask";

import themes from "~/themes";
import * as S from "../styles";

const inputCPF = (props) => {
  const [cpf, setCpf] = useState("");
  const history = useHistory();

  const handleDisabledButton = () => cpf.length !== 14;

  const handleClickButton = () => {
    sessionStorage.removeItem("@NovaEra:numeroTentativas");
    history.push("/Fatura-Loading", {
      cpf: cpf.replace(/\D/gim, ""),
    });
  };

  return (
    <Layout auth={false}>
      <S.Content>
        <S.Main>
          <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
          <p>
            <strong>Bem-vindo</strong> ao Fatura TeuCard
          </p>
          <span>Preencha o campo abaixo para acessar a tua fatura!</span>
          <S.ContentInput>
            <Input
              type="text"
              inputmode="numeric"
              label="CPF"
              placeholder="000.000.000-00"
              maxLength={14}
              value={cpf}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
            />
          </S.ContentInput>
        </S.Main>

        <S.ContentButton>
          <Button
            disabled={handleDisabledButton()}
            onClick={() => handleClickButton()}
          >
            Continuar
          </Button>
        </S.ContentButton>
      </S.Content>
    </Layout>
  );
};

export default inputCPF;
