import styled from "styled-components";

const CardView = styled.div`
  height: ${(p) => (p.height ? `${p.height}px` : "auto")};
  width: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.widthPixel ? `${p.widthPixel}px` : p.width ? `${p.width}%` : "auto"};

  min-width: 360px;
  margin-top: ${(p) => (p.marginTop ? `${p.marginTop}px` : "0px")};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : "0px")};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : "0px")};
  margin-right: ${(p) => (p.marginRight ? `${p.marginRight}px` : "0px")};

  display: flex;

  background-color: white;

  -webkit-align-items: ${(p) => (p.center ? "center" : "none")};
  align-items: ${(p) => (p.center ? "center" : "none")};

  padding: ${(p) => (p.padding ? `${p.padding}px` : "0px")};

  -webkit-justify-content: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.space ? "space-between" : p.center && p.horizontal ? "center" : "none"};

  justify-content: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.space ? "space-between" : p.center && p.horizontal ? "center" : "none"};

  border: 0.5px solid #e8e8e8;
  border-radius: ${(p) => (p.borderRadius ? `${p.borderRadius}px` : "4px")};
  box-shadow: 0 1px 7px 0 rgba(201, 201, 201, 0.5);
`;

export default CardView;
